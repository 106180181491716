<template>
  <div class="loginDialog">

    <el-dialog
      :visible.sync="dialogVisible"
      width="526px"
      title="登录"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="密码登录" name="first">
          <el-form ref="loginForm" :model="loginForm" :rules="registerRules" class="login-form">
            <el-form-item prop="phone">
              <el-input
                v-model="loginForm.phone"
                type="text"
                auto-complete="off"
                placeholder="请输入手机号"
              />
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="loginForm.password"
                type="password"
                auto-complete="off"
                placeholder="请输入密码"
                @keyup.enter.native="handleLogin"
              />
              <div class="Forget" style="float: right;color: rgba(204, 204, 204, 1);width: 160px;height: 30px;">
                <div style="display: flex">
                  <div style="font-size: 14px;" class="link-type" @click="verifyOpen">注册账号</div>
                  <div style="margin: 0 10px;line-height: 38px">|</div>
                  <div style="font-size: 14px;" class="link-type" @click="forgot">忘记密码？</div>
                </div>
              </div>
            </el-form-item>

            <el-form-item style="width:100%;">

              <el-button
                :loading="loading"
                size="medium"
                type="primary"
                style="width:100%;"
                @click.native.prevent="handleLogin"
              >

                <span v-if="!loading">登 录</span>
                <span v-else>登 录 中...</span>
              </el-button>

            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="验证码登录" name="second">
          <el-form ref="passwordForm" :model="passwordForm" :rules="registerRules" class="register-form">
            <el-form-item prop="phone">
              <el-input
                v-model="passwordForm.phone"
                type="text"
                auto-complete="off"
                placeholder="请输入手机号"
              />
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                v-model="passwordForm.code"
                type="text"
                auto-complete="off"
                placeholder="请输入验证码"
                @keyup.enter.native="passwordLogin"
              />

              <el-button
                type="primary"
                plain
                style="padding: 12px 10px"
                :disabled="!verShow"
                @click="GetCode()"
              >
                <span v-if="verShow">
                  获取验证码
                </span>
                <span v-else><span>{{ timer }}</span>秒后重新获取</span>
              </el-button>
              <!--              <el-button disabled type="info" style="padding: 12px 10px" >-->

              <!--              </el-button>-->
              <div class="Forget" style="float: right;color: rgba(204, 204, 204, 1);width: 160px;height: 30px;">
                <div style="display: flex">
                  <div style="font-size: 14px;" class="link-type" @click="verifyOpen">注册账号</div>
                  <div style="margin: 0 10px;line-height: 38px">|</div>
                  <div style="font-size: 14px;" class="link-type" @click="forgot">忘记密码？</div>
                </div>
              </div>
            </el-form-item>

            <el-form-item style="width:100%;">
              <el-button
                :loading="loading"
                size="medium"
                type="primary"
                style="width:100%;"
                @click.native.prevent="passwordLogin"
              >
                <span v-if="!loading">登 录</span>
                <span v-else>请稍后...</span>
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog
      title="忘记密码"
      :visible.sync="forgetShow"
      width="526px"
      :close-on-click-modal="false"
      :before-close="close"
    >
      <el-form ref="registerForm" :model="registerForm" :rules="registerRules" class="register-form">
        <el-form-item prop="phone">
          <el-input v-model="registerForm.phone" type="text" auto-complete="off" placeholder="手机号" />
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            v-model="registerForm.code"
            auto-complete="off"
            placeholder="验证码"
            @keyup.enter.native="handleRegister"
          />
          <el-button
            type="primary"
            plain
            style="padding: 12px 10px"
            :disabled="!verShow"
            @click="GetForgetCode()"
          >
            <span v-if="verShow">
              获取验证码
            </span>
            <span v-else><span>{{ timer }}</span>秒后重新获取</span>
          </el-button>
          <!--          <el-button disabled type="info" style="padding: 12px 10px" >-->
          <!--            <span>{{ timer }}</span>秒后重新获取-->
          <!--          </el-button>-->
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="registerForm.password"
            type="password"
            auto-complete="off"
            placeholder="密码"
            @keyup.enter.native="handleRegister"
          />
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input
            v-model="registerForm.confirmPassword"
            type="password"
            auto-complete="off"
            placeholder="确认密码"
            @keyup.enter.native="handleRegister"
          />
        </el-form-item>
        <el-form-item style="width:100%;">
          <el-button
            :loading="loading"
            size="medium"
            type="primary"
            style="width:100%;"
            @click.native.prevent="handleRegister"
          >
            <span v-if="!loading">确认</span>
            <span v-else>请稍后...</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="isNeedExamine?'试学申请':'注册'"
      width="550px"
      :close-on-click-modal="false"
      :before-close="dialogpassVisibleColse"
      :visible.sync="dialogpassVisible"
    >
      <el-form ref="applyForm" :model="applyForm" class="apply-form" :rules="applyRules">
        <el-form-item prop="name" :label-width="formLabelWidth" style=" margin: 20px 0;">
          <el-input v-model="applyForm.name" autocomplete="off" placeholder="请输入姓名" style="width: 350px" />
        </el-form-item>
        <el-form-item prop="phone" :label-width="formLabelWidth" style=" margin: 20px 0;">
          <el-input v-model="applyForm.phone" type="text" auto-complete="off" placeholder="请输入手机号" style="width: 350px" />
        </el-form-item>
        <el-form-item prop="code" :label-width="formLabelWidth" style=" margin: 20px 0;">
          <el-input
            v-model="applyForm.code"
            style="width: 350px"
            auto-complete="off"
            placeholder="请输入验证码"
            @keyup.enter.native="handleRegister"
          />
          <el-button
            class="apply-bu"
            type="primary"
            plain
            style="padding: 12px 10px;right: -79px"
            @click="getApplyCode"
          >
            <span v-if="applyVerShow">
              获取验证码
            </span>
            <span v-else><span>{{ applytimer }}</span>秒后重新获取</span>
          </el-button>
        </el-form-item>
        <el-form-item prop="qq" :label-width="formLabelWidth" style=" margin: 20px 0;">
          <el-input v-model="applyForm.qq" type="number" placeholder="请输入qq" style="width: 350px" />
        </el-form-item>
        <el-form-item prop="intention" :label-width="formLabelWidth" style=" margin: 20px 0;">
          <el-select v-model="applyForm.intention" placeholder="请选择试听方向" style="width: 350px;display: block">
            <el-option
              v-for="item in auditionDirection"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="isNeedExamine" :label-width="formLabelWidth" style=" margin: 20px 0 10px;">
          <el-input
            v-model="applyForm.remark"
            type="textarea"
            resize="none"
            maxlength="50"
            style="width: 350px"
            show-word-limit
            placeholder="请填写申请说明"
            :autosize="{ minRows: 4, maxRows:6}"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkPassSure">确 定</el-button>
        <el-button @click="dialogpassVisibleColse">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getLogin } from '@/api/welcome'
import { getApplyCode, getCode, getRePassword, getUserInfo, passwordLogin, applyfor, getConfigKey } from '@/api/login'
import { mapState } from 'vuex'

export default {
  name: 'LoginDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogpassVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      auditionDirection: state => state.dict.dictTree.audition_direction['arr']
    })
  },
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.registerForm.password !== value) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      activeName: 'first',
      forgetShow: false,
      loginForm: {},
      verShow: true, // 判断显示哪个按钮
      applyVerShow: true, // 判断显示哪个按钮
      timer: 60, // 短信验证码倒计时
      applytimer: 60, // 短信验证码倒计时
      passwordForm: {},
      registerForm: {},
      registerRules: {
        username: [
          { required: true, trigger: 'blur', message: '请输入您的账号' },
          { min: 2, max: 20, message: '用户账号长度必须介于 2 和 20 之间', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[0-9]{10}$/, message: '请输入正确手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入您的密码' },
          { min: 5, max: 20, message: '用户密码长度必须介于 5 和 20 之间', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, trigger: 'blur', message: '请再次输入您的密码' },
          { required: true, validator: equalToPassword, trigger: 'blur' }
        ],
        code: [{ required: true, trigger: 'blur', message: '请输入验证码' }]
      },
      loading: false,
      // 试学申请弹框
      formLabelWidth: '80px',
      applyForm: {},
      applyRules: {
        name: [
          { required: true, trigger: 'blur', message: '请输入您的姓名' },
          { min: 2, max: 20, message: '用户账号长度必须介于 2 和 20 之间', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[0-9]{10}$/, message: '请输入正确手机号', trigger: 'blur' }
        ],
        qq: [
          { required: true, message: '请输入qq', trigger: 'blur' },
          { pattern: /^[1-9][0-9]{5,20}$/, message: '请输入正确qq', trigger: 'blur' }
        ],
        code: [{ required: true, trigger: 'blur', message: '请输入验证码' }],
        intention: [{ required: true, trigger: 'blur', message: '请选择试听方向' }]
      },
      isNeedExamine: ''
    }
  },
  watch: {
    dialogpassVisible: {
      // 获取注册是否需要审核
      handler(val) {
        if (val) {
          getConfigKey().then(res => {
            this.isNeedExamine = res.msg === 'true'
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('dialogClose')
      this.loginForm = {}
      this.passwordForm = {}
    },
    close() {
      this.forgetShow = false
      this.registerForm = {}
    },
    handleClick() {

    },
    forgot() {
      this.forgetShow = true
    },
    // 试用申请弹框
    verifyOpen() {
      this.$emit('dialogPassClose', true)
    },
    // 确定提交
    checkPassSure() {
      this.$refs.applyForm.validate(valid => {
        if (valid) {
          const obj = {
            ...this.applyForm,
            ...this.$route.query
          }
          applyfor(obj).then(res => {
            console.log(res)
            if (res.code === 200) {
              this.applyForm = {}
              this.$emit('dialogPassClose', false)
              if (this.isNeedExamine) {
                this.$message.success('提交成功，请耐心等待短信回复')
                return
              }
              this.$store.commit('SET_TOKEN', res.token)
              this.$store.dispatch('GET_DICT_LIST')
              getUserInfo().then(res => {
                if (res.code === 200) {
                  this.$store.commit('SET_INFO', res.user)
                  console.log(res.user)
                  this.$router.push('/home')
                }
              })
            }
          })
        }
      })
    },

    dialogpassVisibleColse() {
      this.$emit('dialogPassClose', false)
      this.applyForm = {}
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          getLogin(this.loginForm).then(res => {
            if (res.code === 200) {
              this.loading = false
              this.$emit('close')
              this.$store.commit('SET_TOKEN', res.token)
              this.$store.dispatch('GET_DICT_LIST')
              getUserInfo().then(res => {
                if (res.code === 200) {
                  this.$store.commit('SET_INFO', res.user)
                  console.log(res.user)
                  this.$router.push('/home')
                }
              })
            }
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    passwordLogin() {
      this.$refs.passwordForm.validate(valid => {
        if (valid) {
          // this.$emit('close')
          this.loading = true
          passwordLogin(this.passwordForm).then(res => {
            this.loading = false
            if (res.code === 200) {
              this.$emit('close')
              this.$store.commit('SET_TOKEN', res.token)
              this.$store.dispatch('GET_DICT_LIST')
              getUserInfo().then(res => {
                if (res.code === 200) {
                  console.log('个人数据', res)
                  this.$store.commit('SET_INFO', res.user)
                  console.log(res.user)
                  this.$router.push('/home')
                }
              })
            }
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },

    GetCode() {
      const regEmali = /^1[0-9]{10}$/
      if (regEmali.test(this.passwordForm.phone)) {
        getCode({ phone: this.passwordForm.phone }).then(res => {
          if (res.code === 200) {
            this.verShow = false
            const authTimer = setInterval(() => {
              this.timer--
              if (this.timer <= 0) {
                this.verShow = true
                this.timer = 60
                clearInterval(authTimer)
              }
            }, 1000)
          }
        })
      } else {
        this.$message.error('请输入合法手机号')
      }
    },
    GetForgetCode() {
      const regEmali = /^1[0-9]{10}$/
      if (regEmali.test(this.registerForm.phone)) {
        getCode({ phone: this.registerForm.phone }).then(res => {
          if (res.code === 200) {
            this.verShow = false
            const authTimer = setInterval(() => {
              this.timer--
              if (this.timer <= 0) {
                this.verShow = true
                this.timer = 60
                clearInterval(authTimer)
              }
            }, 1000)
          }
        })
      } else {
        this.$message.error('请输入合法手机号')
      }
    },
    getApplyCode() {
      const regEmali = /^1[0-9]{10}$/
      if (regEmali.test(this.applyForm.phone)) {
        getApplyCode({ phone: this.applyForm.phone }).then(res => {
          if (res.code === 200) {
            this.applyVerShow = false
            const authTimer = setInterval(() => {
              this.applytimer--
              if (this.applytimer <= 0) {
                this.applyVerShow = true
                this.applytimer = 60
                clearInterval(authTimer)
              }
            }, 1000)
          }
        })
      } else {
        this.$message.error('请输入合法手机号')
      }
    },
    handleRegister() {
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          getRePassword({
            code: this.registerForm.code,
            phonenumber: this.registerForm.phone,
            newPassword: this.registerForm.password
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('修改成功')
              this.forgetShow = false
              this.$emit('close')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog__header {
  padding: 30px 0 30px;

}

/deep/ .el-dialog__title {
  color: rgba(67, 146, 253, 1);
  font-size: 20px;
}

/deep/ .el-tabs__nav {
  float: none;
}

/deep/ .el-tabs__item {
  //color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
}

/deep/ .el-tabs__active-bar {
  left: 124px;
}

/deep/ .el-tabs__nav-wrap::after {
  display: none;
}

/deep/ .el-tabs__content {

  width: 394px;
  margin: auto;
  margin-top: 35px;
}

/deep/ el-tabs--top {
  margin-top: 20px;
}

/deep/ .el-button--medium {
  width: 394px;
  height: 44px;
  background: rgba(66.99731279999999, 145.99112529759998, 253.011, 1);
  border-radius: 159px;
  padding-top: 10px;
}
/deep/.el-input{
  padding-bottom: 5px;
}
/deep/ .el-input__inner {
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(204, 204, 204, 1);
}
/deep/.el-button--primary.is-plain{
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  //background-color: white;
}
/deep/.register-form{
  width: 394px;
  margin: auto;
}
/deep/.el-form-item{
  margin-top: 40px;
  margin-bottom: 20px;
}
.Forget{
  position: absolute;
  right: 0px;
  top: 43px;
}
.loginDialog {
  ::v-deep .el-dialog__wrapper {
    max-width: 100% !important;
  }

  ::v-deep.el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }

    .el-tabs__item {
      width: 120px;
    }

    .el-dialog__footer {
      /*text-align: center ;*/
    }
  }

  .link-type{

    color: #4392fd;
  }
  .link-type:hover {
    cursor: pointer;
    color: #2d64b3;
  }
}
.apply-form{
  width: 350px;
}
/deep/input::-webkit-outer-spin-button,
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/input[type='number'] {
  -moz-appearance: textfield !important;
}
/deep/.el-input__inner{
  line-height: 1px!important;
}
</style>
